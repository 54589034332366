import './theme/index.css';
import 'tippy.js/dist/tippy.css';
import tippy from 'tippy.js';
import './slider';
import './lightbox';
import '../home/jsmodules/calendar/front';
import './canvas-nav';

if (process.env.NODE_ENV !== 'production') {
  const listener = new EventSource('http://localhost:9000/reload-listener');
  listener.addEventListener('message', (ev) => {
    if (ev.type === 'message') {
      switch (ev.data) {
        case 'ready':
          console.log('[DTR] Template reloading enabled.');
          break;
        case 'reload':
          console.log('[DTR] Template change detected. Reloading...');
          location.reload();
      }
    }
  });
}

if (!window.plausible) {
  window.plausible = (...args) => window.plausible.q.push(args);
  window.plausible.q = [];
}

function initTooltips() {
  const contents = {};
  for (const item of document.querySelectorAll('.topnav .sub')) {
    contents[item.getAttribute('data-ref')] = item.innerHTML;
  }

  for (const item of document.querySelectorAll('.menu-link.has-sub')) {
    tippy(item, {
      arrow: false,
      interactive: true,
      theme: 'navbar',
      content: contents[item.getAttribute('data-ref')],
      allowHTML: true,
    });
  }

  for (const item of document.querySelectorAll('a[title]')) {
    tippy(item, {
      content: item.getAttribute('title'),
    });
  }
}

function initScrollButton() {
  const trigger = document.querySelector('#scroll-trigger');
  const scrollDest = document.querySelector('#scroll-start');
  if (!trigger || !scrollDest) {
    return;
  }

  trigger.addEventListener('click', function (e) {
    e.preventDefault();
    scrollTo({
      left: 0,
      top: scrollDest.offsetTop,
      behavior: 'smooth',
    });
  });

  addEventListener('scroll', function (e) {
    if (window.scrollY + window.innerHeight - 100 >= scrollDest.offsetTop) {
      trigger.style.opacity = 0;
    } else {
      trigger.style.opacity = 1;
    }
  });

  if (window.scrollY + window.innerHeight - 100 >= scrollDest.offsetTop) {
    trigger.style.opacity = 0;
  }
}

function initPopup() {
  const container = document.querySelector('#popup-container');

  if (!container) {
    return;
  }

  const cookies = document.cookie.split('; ');
  for (const item of cookies) {
    if (item.startsWith('hf_hide_popup=1')) return;
  }

  setTimeout(() => {
    container.classList.remove("opacity-0", "[&>div]:pointer-events-none");
    container.classList.add("opacity-100");
  }, 800);

  for (const trigger of container.querySelectorAll("a")) {
    if (!trigger.href.endsWith("#")) trigger.href += "?ref=site-popup";

    trigger.addEventListener('click', function (e) {
      if (e.currentTarget.href.endsWith("#")) {
          e.preventDefault();
          plausible("Popup geschlossen");
          container.classList.add('hidden');
      } else {
          plausible("Popup-Link angeklickt");
      }

      const d = new Date();
      d.setFullYear(d.getFullYear() + 1);
      document.cookie = 'hf_hide_popup=1;expires=' + d.toUTCString();
    });
  }
}

window.addEventListener('load', initTooltips);
initScrollButton();
initPopup();

